import Head from 'next/head';
import HomeView from '../src/views/pages/HomeView';
import MainLayout from '../src/layouts/MainLayout';

const Home = () => (
  <>
    <MainLayout>
      <Head>
        <title>Skill Exchange | Democratising Learning</title>
      </Head>
      <HomeView />
    </MainLayout>
  </>
);

export default Home;
