import { forwardRef, useEffect, useCallback } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { createFirebaseApp } from 'src/utils/firebase';
import { logEvent } from 'firebase/analytics';
import { initLogRocket } from 'src/utils/logrocket';

// eslint-disable-next-line react/display-name
const Page = forwardRef(({ title, children, ...rest }, ref) => {
  initLogRocket();
  const { pathname, search } = useRouter();

  const completeTitle = `${title} | Skill Exchange`;

  const sendPageViewEvent = useCallback(() => {
    const { analytics: getAnalytics } = createFirebaseApp();
    const analytics = getAnalytics();
    const pagePath = pathname + search;

    if (!analytics) return;

    logEvent(analytics, 'page_view', { page_path: pagePath, page_title: completeTitle });
  }, [completeTitle, pathname, search]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box ref={ref} {...rest}>
      <Head>
        <title>{completeTitle}</title>
      </Head>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
