import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography } from '@mui/material';

const classes = {
  root: {
    backgroundColor: 'background.default',
    py: {
      xs: '60px',
      sm: '60px',
      md: '60px',
      lg: '200px',
      xl: '200px',
    },
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '100%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      // boxShadow: theme.shadows[16]
    },
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
    },
  },
};
function Hero({ className, ...rest }) {
  return (
    <Box sx={classes.root} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Typography variant="overline" color="secondary">
                Democratising learning
              </Typography>
              <Typography variant="h1" color="textPrimary">
                Welcome to Skill Exchange
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  Our vision is to create a community of learners and enable people to teach and
                  learn skills from one another.
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  It can be anything from accounting, computer programming, first aid, painting,
                  fixing tyre puncture, parenting and many other things.
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Everyone can be mentors and mentees at the same time.
                </Typography>
              </Box>
              {/* <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      30+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Demo Pages
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Complete Flows
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Components
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              {/* <div sx={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div> */}
              <Box sx={classes.image}>
                <img
                  alt="Learn and teach at Skill Exchange community"
                  src="/static/home/people-converse-02.jpg"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
