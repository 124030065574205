import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Divider, Toolbar, Link } from '@mui/material';
import Logo from 'src/components/Logo';
function TopBar({ className, ...rest }) {
  return (
    <AppBar sx={{ backgroundColor: 'background.default' }} color="default" {...rest}>
      <Toolbar sx={{ height: 64 }}>
        <NextLink href="/" passHref>
          <Link sx={{ lineHeight: 0 }}>
            <Logo sx={{ marginRight: 2 }} />
          </Link>
        </NextLink>
        <Box flexGrow={1} />
        <NextLink href="/login" passHref>
          <Link
            color="textSecondary"
            underline="none"
            variant="body2"
            sx={{
              fontWeight: 'fontWeightMedium',
              '& + &': {
                marginLeft: 2,
              },
            }}
          >
            Sign in
          </Link>
        </NextLink>
        <Divider
          sx={{
            width: '1px',
            height: 32,
            marginLeft: 2,
            marginRight: 2,
          }}
        />
        <NextLink href="/register" passHref>
          <Button color="secondary" component="a" variant="contained" size="small">
            Register
          </Button>
        </NextLink>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
