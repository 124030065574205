import React from 'react';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
// import Testimonials from './Testimonials';
import CTA from './CTA';
// import FAQS from './FAQS';

const classes = {
  root: {},
};

function HomeView() {
  return (
    <>
      <Page sx={classes.root} title="Democratising learning through the power of community">
        <Hero />
        <Features />
        {/* <Testimonials /> */}
        <CTA />
        {/* <FAQS /> */}
      </Page>
    </>
  );
}

export default HomeView;
