import PropTypes from 'prop-types';
import TopBar from './TopBar';
import { Box } from '@mui/material'
function MainLayout({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <TopBar />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: '64px'
        }}
      >
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden'
          }}
        >
          <Box sx={{
              flex: '1 1 auto',
              height: '100%',
              overflow: 'auto'
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};

export default MainLayout;
