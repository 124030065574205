import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar, Box, Container, Grid, Typography
} from '@mui/material';
import {
  Feather as FeatherIcon,
  Book as BookIcon,
} from 'react-feather';

const classes = {
  root: {
    backgroundColor: 'background.dark',
    paddingTop: '128px',
    paddingBottom: '128px'
  },
  avatar: {
    backgroundColor: 'secondary.main',
    color: 'secondary.contrastText'
  }
};

function Features({ className, ...rest }) {
  return (
    <Box
      sx={classes.root}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Learning or teaching
        </Typography>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
        >
          Choose your objective
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box display="flex">
                <Avatar sx={classes.avatar}>
                  <BookIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    color="textPrimary"
                  >
                    Learn
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Find someone who has a skill set or topic you want to learn,
                    or get a grasp at it.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    You can request a session with them to have further
                    discussion and learning suitable for you either online or in-person.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box display="flex">
                <Avatar sx={classes.avatar}>
                  <FeatherIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    color="textPrimary"
                  >
                    Teach
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Make yourself available to help other people
                    learn more of topics or skills you can share.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    You can choose what suits you the most,
                    whether it is one-time teaching or ongoing mentorship.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
