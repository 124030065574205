import PropTypes from 'prop-types';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import NextLink from 'next/link';

const classes = {
  root: {
    backgroundColor: 'background.default',
    py: '128px',
  },
  browseButton: {
    marginLeft: 2,
  },
  image: {
    textAlign: 'center',
    '& > img': {
      maxWidth: '100%',
    },
  },
};

function CTA({ className, ...rest }) {
  return (
    <Box sx={classes.root} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box position="relative" justifyContent="center" height="100%">
              <Box sx={classes.image}>
                <img alt="Join Skill Exchange" src="/static/home/people-converse-01.jpg" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Typography variant="h2" align="center" color="textPrimary">
                Join us
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary" align="center">
                  Register now and get involved in the community.
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center">
                  Let&apos;s democratise learning.
                </Typography>
              </Box>
              <Box mt={6} display="flex" justifyContent="center" alignItems="center">
                <NextLink href="/register" passHref>
                  <Button color="secondary" component="a" variant="contained">
                    Register
                  </Button>
                </NextLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

CTA.propTypes = {
  className: PropTypes.string,
};

export default CTA;
