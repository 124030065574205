import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const initLogRocket = () => {
  if (process.env.NODE_ENV === 'production') {
    if (typeof window !== 'undefined') {
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
        release: process.env.NEXT_PUBLIC_VERSION,
        rootHostname: 'skill.exchange',
        dom: {
          inputSanitizer: true,
        },
      });

      setupLogRocketReact(LogRocket);

      return LogRocket;
    }
  }
};

export default LogRocket;
